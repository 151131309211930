@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'calibri', Montserrat, sans-serif, 'Segoe UI', Tahoma,
    Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow: hidden;
}
body {
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: #f9f9f9;
  padding-bottom: env(safe-area-inset-bottom);
}

p {
  margin: 0;
  margin-bottom: 0 !important;
}

[x-cloak] {
  display: none !important;
}

/* --------------------------Objetive Fonts-------------------------- */

@font-face {
  font-family: ObjectiveBold;
  src: url(/public/fonts/objective/Objective-Bold.otf);
}

@font-face {
  font-family: ObjectiveRegular;
  src: url(/public/fonts/objective/Objective-Regular.otf);
}

@font-face {
  font-family: ObjectiveExtraBold;
  src: url(/public/fonts/objective/Objective-ExtraBold.otf);

}

@font-face {
  font-family: ObjectiveMedium;
  src: url(/public/fonts/objective/Objective-Medium.otf);

}